import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import axios from "axios"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// import images
import bg from "../../assets/images/bg.jpg"
import logoSm from "../../assets/images/klikcrm-logo.png"
import { setCurrentUser, setPermission } from "../../helpers/Utils"
import { useHistory } from "react-router-dom"

let subDomain

const Login2 = props => {
   const [loading, setLoading] = useState(false)
   const [login, setLogin] = useState(false)
   const history = useHistory()
   const [error, setError] = useState("")
   const [success, setSuccess] = useState("")
   const [companyName, setCompanyName] = useState("")

   useEffect(() => {
      subDomain = window.location.host.split(".")[0]
      if (subDomain !== "login") {
         setCompanyName(subDomain)
         setLogin(false)
      } else {
         setLogin(true)
      }
   }, [])

   const handleValidSubmit = (event, value) => {
      let loginCompany = "0"
      if (login) {
         loginCompany = "1"
      }
      localStorage.setItem("company_name", companyName)
      if (companyName !== "") {
         setLoading(true)
         axios
            .post(`${process.env.REACT_APP_API_URL}/${companyName}/auth/login`, {
               email: value.email,
               password: value.password,
               company_type: loginCompany,
            })
            .then(res => {
               if (res.data.status) {
                  if (login) {
                     window.location.replace(
                        `http://${companyName}.klikcrm.com?token=${res.data.data.login_token}`
                     )
                     setCurrentUser(res.data.data)
                     setPermission(res.data.data.permissions)
                  } else {
                     setCurrentUser(res.data.data)
                     setPermission(res.data.data.permissions)
                     history.push("/dashboard")
                     toast.success(res.data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                     })
                     setLoading(false)
                  }
               } else {
                  setLoading(false)
                  toast.error(res.data.message, {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  })
               }
            })
            .catch(err => {
               console.log("err", err)
            })
      } else {
         toast.error("Enter Company Name", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
         })
      }
   }

   return (
      <React.Fragment>
         <div className="account-pages">
            <MetaTags>
               <title>Login | KlikCRM</title>
            </MetaTags>
            <div
               className="accountbg"
               style={{
                  background: `url(${bg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
               }}
            ></div>

            <div className="wrapper-page account-page-full">
               <Card className="shadow-none">
                  <div className="card-block">
                     <div className="account-box">
                        <div className="card-box shadow-none p-4">
                           <div className="p-2">
                              <div className="text-center mt-4">
                                 <div className="text-center mt-4">
                                    <img src={logoSm} height="42" alt="logo" />
                                 </div>

                                 <h4 className="font-size-18 mt-5 text-center">
                                    Welcome Back !
                                 </h4>
                                 <p className="text-muted text-center">
                                    Sign in to continue to KlikCRM.
                                 </p>
                              </div>
                              <AvForm
                                 className="form-horizontal mt-4"
                                 onValidSubmit={(e, v) => {
                                    handleValidSubmit(e, v)
                                 }}
                              >
                                 {error ? <Alert color="danger">{error}</Alert> : ""}
                                 {success ? <Alert color="success">{success}</Alert> : ""}
                                 {subDomain == "login" ? (
                                    <div className="mb-3">
                                       <label>Domain Name</label>
                                       <div className="input-group mb-3">
                                          <input
                                             onChange={e => setCompanyName(e.target.value)}
                                             type="text"
                                             className="form-control"
                                             placeholder="Enter Domain Name"
                                             aria-label="Recipient's username"
                                             aria-describedby="basic-addon2"
                                             required
                                          />
                                          <span
                                             className="input-group-text"
                                             id="basic-addon2"
                                          >
                                             .successcrm.in
                                          </span>
                                       </div>
                                    </div>
                                 ) : (
                                    ""
                                 )}
                                 <div className="mb-3">
                                    <AvField
                                       name="email"
                                       label="Email"
                                       className="form-control"
                                       placeholder="Enter email"
                                       type="email"
                                       required
                                    />
                                 </div>

                                 <div className="mb-3">
                                    <AvField
                                       name="password"
                                       label="Password"
                                       type="password"
                                       required
                                       placeholder="Enter Password"
                                    />
                                 </div>

                                 <Row className="mb-3">
                                    <Col sm={6}>
                                       <div className="form-check">
                                          <input
                                             type="checkbox"
                                             className="form-check-input"
                                             id="customControlInline"
                                          />
                                          <label
                                             className="form-check-label"
                                             htmlFor="customControlInline"
                                          >
                                             Remember me
                                          </label>
                                       </div>
                                    </Col>
                                    <Col sm={6} className="text-end">
                                       {loading ? (
                                          <button
                                             className="btn btn-primary w-md waves-effect waves-light"
                                             type="button"
                                             disabled
                                          >
                                             Loading
                                          </button>
                                       ) : (
                                          <button
                                             className="btn btn-primary w-md waves-effect waves-light"
                                             type="submit"
                                          >
                                             Log In
                                          </button>
                                       )}
                                    </Col>
                                 </Row>
                                 <Row className="mt-2 mb-0 row">
                                    <div className="text-center col-12 mt-4">
                                       <Link
                                          to="/forgot-password"
                                          className="text__color__styling"
                                       >
                                          <i className="mdi mdi-lock"></i> Forgot your
                                          password?
                                       </Link>
                                    </div>
                                 </Row>
                              </AvForm>
                              <div className="mt-3 pt-3 d-flex justify-content-between">
                                 {/* <div>
                        <label className="">Support</label> <br />
                        <i className="fas fa-headset fa-lg"></i>
                        <a
                          className="text-info ms-2"
                          href="tel://+918054805705"
                        >
                          +91-805 4805 705
                        </a>
                      </div> */}
                                 <div>
                                    <label className="">Sales & Support</label> <br />
                                    <i className="fas fa-users fa-lg"></i>
                                    <a
                                       className="text-info ms-2"
                                       href="tel://+919781239781"
                                    >
                                       +91-9781 23 9781
                                    </a>
                                 </div>
                              </div>

                              <div className="mt-5 pt-4 text-center">
                                 {/* <p>
                                   Don't have an account ?{" "}
                                   <a href="https://klikcrm.com"
                                     className="fw-medium-medium text-primary"
                                   >
                                     {" "}
                                   <span className="text__color__styling">Signup now{" "}
                                   </span>
                                   </a>{" "}
                                 </p> */}
                                 <p>
                                    © {new Date().getFullYear()} KlikCRM.com,{" "}
                                    {/* <i className=""></i>  */}
                                    all rights reserved.
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </Card>
            </div>
         </div>
      </React.Fragment>
   )
}

export default Login2

Login2.propTypes = {
   error: PropTypes.any,
   history: PropTypes.object,
   loginUser: PropTypes.func,
}
