import React from 'react'
import MetaTags from "react-meta-tags"
import { Container, Row, Col } from "reactstrap"
import Facebook from '../../components/Admin/profileComps/facebook'

const facebookConnect = () => {
   return (
      <React.Fragment>
         <div className="page-content">
            <MetaTags>
               <title>Connect With Facebook | KlikCRM</title>
            </MetaTags>
            <Container fluid>
               <Facebook />
            </Container>
         </div>
      </React.Fragment>
   )
}

export default facebookConnect